body{
    font-size:2.4rem;
    background:#fff;
    font-family: "JetBrains Mono", monospace;
    
    
    
}
.sperm{
    background:url(/images/image_processing20200626-22041-8ryv13.gif)
}
@media screen and (min-width:800px){
    .parte_dos{
        margin-top:-2rem !important; 
        
       }
 }
.input-type{
    font-size:1rem !important; 
    font-family:  "JetBrains Mono", monospace !important;
    display:block !important; 
    width:100%  !important; 
    margin-top:3rem !important; 
    border-radius:0% !important; 
    border:0 !important; 
    border-bottom:1px solid #000 !important;
    background:transparent !important; 
    color:#000 !important;

}.input-type::placeholder{
    font-family:  "JetBrains Mono", monospace !important;
    font-size:0.9rem !important;
    width:100% !important; 
    color:#000 !important;
}.input-type:focus{
    outline:none !important; 
    border-bottom:2px solid #000 !important; 
}.input-type:focus::placeholder{
    font-weight:600 !important;  
    color:#000 !important;
}
.popup{
   
    border-radius: 6px; 
    position:absolute; 
    top:50%; 
    left:50%;
    transform:translate(-50%,-50%) ;
    text-align:center; 
    padding:0 5rem 5rem; 
    color:#000; 
    visibility: hidden;
    transition:transform 0.4s , top 0.4s;
    background:#fff; 
    border:1px solid #000; 
    background:url(https://i.makeagif.com/media/3-07-2018/sWmlMp.gif);
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;

}.arrow:hover{
    background:transparent !important;
}
.titulo-form,.cantidad-form{
    padding:0 !important; 
}
.popup2{
    
    border-radius: 6px; 
    position:absolute; 
    top:50%; 
    left:50%;
    transform:translate(-50%,-50%) ;
    text-align:center; 
    padding:0 5rem 5rem; 
    color:#000; 
    visibility: hidden;
    transition:transform 0.4s , top 0.4s;
    background:#fff; 
    border:1px solid #000; 
    background:url(https://i.makeagif.com/media/3-07-2018/sWmlMp.gif);
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;

}
.open-popup{
    visibility: visible;
    top:50%; 
    transform:translate(-50%,-50%) scale(1);
}.open-popup2{
    visibility: visible;
    top:50%; 
    transform:translate(-50%,-50%) scale(1);
}
.button{
    background:#fff; 
    border:transparent; 
    color:#000; 
   margin-left:-.3rem; 
   font-family: inherit;
    font-size:1rem; 
    cursor:pointer; 
    border:1px solid #000; 
    border-radius:6px; 
    margin-top:2rem; 
}.button:hover{
    background:#e6e2e2
}.button:hover *{
    color:#fff; 
}
.poemass-2{
    border:2px solid #000 !important; 

}
.tit{
    font-size:0.8rem; 
}

.drawing{
    width:100%; 
    height:100%; 
    object-fit:cover; 
    position:fixed; 
    z-index:-1; 
    top:0; 
    left:0; 
    opacity:0.5; 
}
.hormiguero{
    justify-content: center;
    display:flex; 
    align-content:center;
}

.poema{
    margin-top:7rem; 
    justify-content: center;
   
    align-content:center;
    
    width:50%; 
    height:60vh; 
}
.first-res{
    margin-top:5rem; 
    height:80% !important;  
    
}.titulos-populares{
    padding-right:2rem!important; 
    padding-left:2rem;
}
@media screen and (min-width:768px) {
    .titulo-fermentado{
        font-size: 2.7rem !important; 
    }
    
}
.poemass-22{
    border:5px solid #fff !important;
}
.carousel-indicators{
    position:fixed !important;
    bottom:20rem !important; 
    margin-right:5rem !important;
}
.carousel{
    
    background:#fff; 
    height:50%; 
    width:100%; 
}
.carousel-item h2,p{
    margin-top:12rem; 
   align-content:center;  
    justify-content:center;
    color:#000; 
    display: flex;
}
.carousel-item2 h2,p{
    margin-top:2rem; 
   align-content:center;  
    justify-content:center;
    color:#000; 
    display: flex;
}
.container{
    max-width:1140px; 
    margin: 0 auto; 
    overflow:hidden; 
   
}
.list{
    list-style:none; 
    padding-left:0;
   
    
}@media screen and (max-width:380px){
    .titulos-nuevos-largos{
        font-size: 1.73rem !important;
    }
}

.nav{
    color:#000; 
    display:flex;
    justify-content:space-between; 
    flex-wrap:wrap; 
    padding:0 1rem; 
    align-items:center; 
    
}

.nav__list{
    width:100%; 
    margin:0; 
    justify-content: center;
    display:flex;


}.list {
    text-decoration: none;
    font-size:1.2rem; 
}

.nav__item{
    padding:0.5em 2rem; 
    

}

.nav__item >a{
    color:#000; 
    transition:color .3s; 
}

.nav__item >a:hover{
    color:#4f4545; 
}

.nav__toggler{
  
   transition:box-shadow .15s; 
   cursor:pointer; 
   margin-top:5rem; 
}

.nav.collapsible--expanded .nav__toggler{
    opacity:1; 
    
} 

.nav__brand{
     
    transform:translateY(5px); 
}
.headertest{
    background:#fff;
    position:fixed; 
    z-index: 100;
    width:100%; 
    margin-top:-2rem; 
    margin-bottom:1rem; 
}.headertest-2{
    margin-top:-5rem !important; 
}
@media screen and (min-width:768px){
    .nav__toggler{
        display:none; 
    }
    .nav__list {
        width:auto; 
        display:flex;
        
        max-height:100%; 
        opacity:1;
        

    }.nav__list__2{
        margin-top:-2rem !important; 
    }
    .nav__item{
        border:0; 
        
    }.collapsible{
        pointer-events: none;
       
        
    }.collapsible a{
        pointer-events:all;
        font-size:2rem ;     
    }.grid-item2:hover{
        transform:scale(1.1) !important; 
        
    }
    .headertest{
        background:#fff;
        position:fixed; 
        z-index: 100;
        width:100%; 
        margin-top:2rem; 
        margin-bottom:1rem; 
    }.headertest-2{
        margin-top:1rem !important; 
    }
    
    .grid-item2-first{
        margin-top:-5rem; 
    }.first-res{
        height:100%; 
    }.historia-detras{
        font-size:1.5rem !important; 
       margin-left:-14rem !important; 
    }.historia-detras-imagen{
        margin-top:-9rem !important; 
        margin-left:-7.5rem !important; 
        font-size:1.5rem; 
    }.cantidad-form{
        font-size:1rem !important;
    }.poemass3{
        height: 100vh !important;
        width: 70vh !important;
    }

}@media screen and (max-width:768px){
    .cantidad2{
        font-size:0.65rem !important; 
    }.imagenesrancias{
        margin-top:-2rem;
    }
}.tit2{
        padding-left:0 !important;
    }
.titulos-populares,.cantidad,.imagenes { 
    opacity:1; 
    transition: opacity 0.5s ease; /* Agrega una transición suave */ 
}.historia-detras{
    display:none; 
}.grid-item2:hover .historia-detras{
    opacity:1 !important; 
}
.historia-detras{
    padding-left:0; 
    font-size:0.6rem; 
    text-align:left; 
    margin-left:-3.5rem; 
    margin-right:1rem; 
    font-family: inherit;
}.historia-detras-imagen{
    margin-top:-6rem; 
    font-size:1rem; 
}
@media screen and (max-width:800px){
    .imagenes2{
        max-height:100% !important;
    }
}




/* jQuery enabled behavior for mobile */

.icon{
    width:5rem; 
    height:30px;
}
.icon--primary{
    fill:var(--color-primary);
}

.icon--small{
    width:30px; 
    height:30px; 
}



.icon-container{
    margin-top:-10rem;
    width:64px; 
    height:64px; 
    border-radius:100%; 
    display:inline-flex;
    justify-content:center; 
    align-items:center; 
    gap:4rem; 
    
}
.autor{
    padding-left:0 !important; 
}@media screen and (min-width:768px){
    .biografia{
        font-size:1.5rem !important; 
        max-width:55ch !important; 
        
    }.demi{
        padding-left:2rem !important;
    }
}
.biografia{
    max-width:50ch; 
}.contacto p{
    display:flow; 
}
.icon-container--accent{
    background:var(--color-accent);
}
.contactarme{
    min-height:100vh; 
}.poemass3{
    border:none !important;
    
}.poemass3 img{
    max-width:none!important; 
}

.grid-container2 {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items:center;
    gap:5rem; 
  }
.grid-item2{
    justify-content: center;
    align-content:center;
   
    height:100%; 
    
}

.button-arrow:hover{
    transform:scale(1) !important; 
    background:#fff !important; 
}
.poemass-carrusel{
    margin-top:0rem !important; 
    margin-left:3.1rem !important;
    width:70% !important;  

}
.icon--white {
    background: white !important;
    stroke: white; /* Optional, for borders or outlines */
  }
.options{
    margin-top:-3rem;
    justify-content:center; 
    overflow: hidden !important;
}.button-contact{
    margin-top:20rem !important; 
}
.poemass{
    margin-top:5rem; 
    justify-content: center;
    align-content:center;
    border-right: 2px solid #000;
    border-bottom: 3.5px solid #000;
    border-top: 2px solid #000;
    border-left: 7px solid #000;
    
    overflow: hidden;
    text-align: center;
    height:100%;
    width:100%; 
   
    
   
}.cantidad{
    margin-top:0rem; 
    font-size: 1rem ;
    color:#0b0b0b;
    padding-left:2rem; 
    padding-right:2rem; 
}.titulos{
    font-size: 1.8rem ;
    
    padding-right: 1rem;
    color:#000;
}.titulos:hover{
    color:#464040;
}.titulos-main{
    align-content:center;
    display: flex;
    gap:1rem; 
    margin-top:5rem; 
    font-size:1.5rem; 
}.titulos22{
    font-size:1rem !important;
}.titulos22-special{
    font-size:0.8rem !important;
}
.poemass img{
    width:100%; 
    margin-bottom:-1rem;
    height:100%; 
    object-fit:cover; 
    
}.poemass-4{
    margin-top:5rem; 
    margin-bottom:15rem !important; 
}.titulos-33{
    margin-top:-7.5rem; 
   
}
@media screen and (min-width:768px){
    .poemass{
        height:50vh; 
        width:35vh; 
        place-content: center;
        border-right: 3px solid #000;
        border-bottom: 5px solid #000;
        border-top: 3px solid #000;
        border-left: 10px solid #000;
        
    }.grid-container2{
        gap:7rem; 
    }.titulos{
        font-size: 3rem ;
    }.cantidad{
        font-size: 2.5rem ;
    }.poemass img{
        width:100%; 
        max-width:100%; 
        max-height: 100%;
        height:100%; 
        object-fit:cover; 
    
    }.titulos-main{
        margin-top:-0.5rem; 
    }.titulos-special{
        margin-top:3.3rem !important; 
        font-size:2.5rem !important; 
    
      }.titulos22{
        font-size:3.3rem !important; 
      }.titulos22-special{
        font-size:2rem !important; 
      }
}
.titulos-special2{
    font-size:0.8rem; 
}
.scroller {
    --scrollbar-color-thumb: hotpink;
    --scrollbar-color-track: blue;
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 0.5rem;
  }

.containere{
    margin-top:1rem; 
    justify-content: left;
    
   
   
    
}
.titulos-poema{
        margin-top:3.3rem; 
        padding-left:7rem; 
        margin-bottom:2rem; 
        font-weight:600; 
    
}.poem2{
    font-family:"JetBrains Mono", monospace;
    padding-left:0 !important;
}.poem{
    justify-content: left;
}@media screen and (min-width:768px){
    .titulos-poema{
        margin-top:5rem; 
        padding-left:10rem; 
        margin-bottom:4rem; 
        font-weight:600; 
        
    }.poem2{
        font-size:1.5rem !important; 

    }
}
.button{
    background:#fff; 
    border:transparent; 
    color:#000; 
    
}

.button:hover{
    
    color:#434040;     
    cursor:pointer; 
    transform:scale(1.2)
} .nuevo-badge {
    background-color: #000; /* Color de fondo */
    color: white;          /* Color del texto */
    font-size: 12px;       /* Tamaño de la fuente */
    padding: 2px 5px;      /* Espaciado interno */
    border-radius: 5px;    /* Bordes redondeados */
     
    border:1px solid #fff !important;    /* Separación del texto */
  }

  /* Add this to your existing CSS */
/* Add this to your existing CSS */
.custom-alert {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    background: rgba(255, 255, 255, 0.95);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    text-align: center;
    min-width: 300px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    
  }
  
  .custom-alert.show {
    opacity: 1;
    visibility: visible;
    background:url(https://i.makeagif.com/media/3-07-2018/sWmlMp.gif);
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
  }
  
  .alert-title {
    font-family: 'JetBrains Mono', monospace;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
  }
  
  .alert-message {
    font-family: 'JetBrains Mono', monospace;
    color: #4a4a4a;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .alert-button {
    font-family: 'JetBrains Mono', monospace;
    background: #1a1a1a;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease;
  }
  
  .alert-button:hover {
    background: #333;
    transform: translateY(-1px);
  }
  
  .alert-button:active {
    transform: translateY(0);
  }
  
  .alert-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    transition: opacity 0.2s ease;
  }
  
  .alert-close:hover {
    opacity: 1;
  }
  
  .alert-close::before,
  .alert-close::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 16px;
    background-color: #1a1a1a;
    transform-origin: center;
  }
  
  .alert-close::before {
    transform: rotate(45deg);
  }
  
  .alert-close::after {
    transform: rotate(-45deg);
  }
  
  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 999;
  }
  
  .alert-overlay.show {
    opacity: 1;
    visibility: visible;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }